import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Numbered from "../components/Headings/Numbered"
import Heading from "../components/Headings/Heading"
import SubHeading from "../components/Headings/SubHeading"
import Sidebar from "../components/Sidebar/Sidebar"
import {
  FaArrowLeft,
  FaCalendarCheck,
  FaIdBadge,
  FaIndustry,
  FaLaptopMedical,
  FaTools,
} from "react-icons/fa"
import styled from "styled-components"
import Accordion from "../components/Sidebar/Accordion"
import parseCategoryData from "../utils/parseCategoryData"
import Title from "../components/Headings/Title"

const Wrap = styled.div`
  max-height: 77vh;
  display: flex;
`

const InnerScroll = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
`

const BMIPage = ({ data }) => {
  const subjectCategories = parseCategoryData(data.allMdx.edges)
  const [ToggleSidebar, setToggleSidebar] = useState(true)
  const toggle = () => setToggleSidebar(!ToggleSidebar)

  return (
    <Layout>
      <Seo title="Biomedical Instrumentation" />
      <section className="bmi-section">
        <Title>Biomedical Instrumentation</Title>
        <div className="container-fluid py-5">
          <div className="row justify-content-center">
            {ToggleSidebar && (
              <div
                className="col-lg-3 mb-4"
                id="sticky-sidebar"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div
                  className="card shadow border-primary sticky-top"
                  style={{
                    backgroundColor:
                      "#" + Math.random().toString(16).slice(-6) + "10",
                  }}
                >
                  <div className="card-header text-center">
                    <h5 className="fat-text">Chapters</h5>
                  </div>
                  <Wrap>
                    <InnerScroll className="card-body small no-scrollbar">
                      <Accordion allowMultipleOpen>
                        {Object.keys(subjectCategories).map((category, i) => {
                          return (
                            <div key={i} label={category} isOpen>
                              <Sidebar
                                subjectLink={`biomedical_instrumentation`}
                                category={subjectCategories[category]}
                                target="ToggleSidebar"
                              />
                            </div>
                          )
                        })}
                      </Accordion>
                    </InnerScroll>
                  </Wrap>
                </div>
              </div>
            )}

            <div className={ToggleSidebar ? "col-lg-9 mb-4" : "col-lg-12 mb-4"}>
              <div
                className="card border-primary shadow"
                style={{
                  backgroundColor:
                    "#" + Math.random().toString(16).slice(-6) + "10",
                }}
              >
                <div className="card-header">
                  <button
                    onClick={toggle}
                    id="ToggleSidebar"
                    className="btn btn-outline-primary btn-sm"
                  >
                    <FaArrowLeft /> Toggle Sidebar
                  </button>
                </div>

                <div className="card-body">
                  <Heading>What is Biomedical instrumentation ?</Heading>
                  <p>
                    Biomedical instrumentation refers to the use of electronic
                    devices and instruments to diagnose, monitor, and treat
                    medical conditions. Biomedical instrumentation systems are
                    used in a variety of healthcare settings, including
                    hospitals, clinics, and research laboratories. These systems
                    can range from simple handheld devices to complex
                    computerized systems that incorporate advanced signal
                    processing algorithms and artificial intelligence.
                    <br />
                    <br />
                    Biomedical instrumentation systems can be used to measure a
                    variety of physiological parameters, such as heart rate,
                    blood pressure, respiration rate, and brain activity.
                    Examples of common biomedical instrumentation systems
                    include electrocardiography (ECG), which measures the
                    electrical activity of the heart, and electroencephalography
                    (EEG), which measures the electrical activity of the brain.
                    Other examples of biomedical instrumentation systems include
                    blood glucose monitors, pulse oximeters, and imaging devices
                    such as X-ray, MRI, and CT scanners.
                    <br />
                    <br />
                    Biomedical instrumentation is a rapidly evolving field that
                    combines principles from engineering, biology, physics, and
                    computer science. The development of new and innovative
                    biomedical instrumentation systems has led to improved
                    healthcare outcomes for patients, with faster and more
                    accurate diagnoses, more effective treatments, and better
                    monitoring of medical conditions.
                    <br />
                    <br />
                    Overall, biomedical instrumentation plays a critical role in
                    modern healthcare, providing accurate and reliable data for
                    diagnosis, monitoring, and treatment of various medical
                    conditions. The development and application of biomedical
                    instrumentation systems is expected to continue to grow as
                    the demand for healthcare technology increases.
                    <br />
                  </p>
                  <Heading>
                    What are the types of Biomedical Instrumentation ?
                  </Heading>
                  <p>
                    <SubHeading>Imaging equipment</SubHeading> Imaging equipment
                    is used to capture images of the human body for diagnostic
                    purposes. Examples include X-ray machines, magnetic
                    resonance imaging (MRI) scanners, computed tomography (CT)
                    scanners, and ultrasound machines.
                    <br />
                    <br />
                    <SubHeading>Vital sign monitors</SubHeading> Vital sign
                    monitors are used to measure and record the body's vital
                    signs, such as heart rate, blood pressure, and respiratory
                    rate. These monitors can be used in hospitals, clinics, and
                    home healthcare settings.
                    <br />
                    <br />
                    <SubHeading>Electrocardiographs (ECG)</SubHeading> ECGs are
                    used to record the electrical activity of the heart. This
                    information can be used to diagnose various heart
                    conditions.
                    <br />
                    <br />
                    <SubHeading>Blood glucose meters</SubHeading> Blood glucose
                    meters are used to measure the level of glucose in the
                    blood, which is essential for people with diabetes to
                    monitor their blood sugar levels.
                    <br />
                    <br />
                    <SubHeading>Ventilators</SubHeading> Ventilators are used to
                    assist with breathing in patients who are unable to breathe
                    on their own due to various medical conditions.
                    <br />
                  </p>
                  <Heading>
                    What are the applications of Biomedical Instrumentation ?
                  </Heading>
                  <p>
                    <SubHeading>Diagnosis</SubHeading> Biomedical
                    instrumentation plays a critical role in the diagnosis of
                    various medical conditions. Imaging equipment and ECGs can
                    help detect tumors, heart disease, and other conditions.
                    <br />
                    <br />
                    <SubHeading>Monitoring</SubHeading> Vital sign monitors and
                    other biomedical instruments are used to monitor patients'
                    health and detect changes in their condition.
                    <br />
                    <br />
                    <SubHeading>Treatment</SubHeading> Biomedical
                    instrumentation is used in various medical procedures, such
                    as surgery, to assist with patient monitoring and management
                    of medical equipment.
                    <br />
                    <br />
                    <SubHeading>Research</SubHeading> Biomedical instrumentation
                    is also used in medical research to study the effects of
                    medications and treatments on the body.
                    <br />
                  </p>
                  <Heading>
                    What are the impact of Biomedical Instrumentation on
                    Healthcare ?
                  </Heading>
                  The use of biomedical instrumentation has revolutionized
                  healthcare in many ways. Here are some examples of the impact
                  of biomedical instrumentation on healthcare:
                  <br />
                  <br />
                  <p>
                    <SubHeading>Improved accuracy</SubHeading> Biomedical
                    instrumentation provides more accurate and reliable data
                    than traditional methods of monitoring and diagnosing
                    medical conditions.
                    <br />
                    <br />
                    <SubHeading>Early detection</SubHeading> Biomedical
                    instrumentation can help detect medical conditions at an
                    early stage, making it easier to treat and manage.
                    <br />
                    <br />
                    <SubHeading>Better patient outcomes</SubHeading> Biomedical
                    instrumentation can help medical professionals make better
                    treatment decisions, leading to better patient outcomes.
                    <br />
                    <br />
                    <SubHeading>Increased efficiency</SubHeading> Biomedical
                    instrumentation can help healthcare providers work more
                    efficiently, allowing them to treat more patients in less
                    time.
                    <br />
                    <br />
                    <SubHeading>Remote monitoring</SubHeading> Biomedical
                    instrumentation has also enabled remote monitoring of
                    patients, allowing medical professionals to monitor
                    patients' health from a distance and provide timely
                    interventions.
                    <br />
                  </p>
                  <Heading>
                    Why you should learn Biomedical instrumentation ?
                  </Heading>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaCalendarCheck />
                        </div>
                        <h4>Career Opportunities</h4>
                        <p>
                          Biomedical instrumentation is a growing field with
                          increasing demand for skilled professionals. Learning
                          biomedical instrumentation can open up a wide range of
                          career opportunities in fields such as medical device
                          design, biomedical engineering, and healthcare
                          technology.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaLaptopMedical />
                        </div>
                        <h4>Impact on Healthcare</h4>
                        <p>
                          Biomedical instrumentation plays a critical role in
                          modern healthcare, providing accurate and reliable
                          data for diagnosis, monitoring, and treatment of
                          various medical conditions. By learning biomedical
                          instrumentation, you can contribute to improving
                          healthcare outcomes for patients.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaIndustry />
                        </div>
                        <h4>Interdisciplinary Field</h4>
                        <p>
                          Biomedical instrumentation is an interdisciplinary
                          field that combines principles from engineering,
                          biology, physics, and computer science. Learning
                          biomedical instrumentation can help you develop a
                          broad range of skills and knowledge that can be
                          applied in a variety of fields.
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaTools />
                        </div>
                        <h4>Innovation</h4>
                        <p>
                          Biomedical instrumentation is a rapidly evolving field
                          with constant advancements in technology. Learning
                          biomedical instrumentation can help you stay up to
                          date with the latest innovations and contribute to the
                          development of new and improved medical devices and
                          technologies.
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaIdBadge />
                        </div>
                        <h4>Personal Interest</h4>
                        <p>
                          Biomedical instrumentation can be a fascinating
                          subject to learn for anyone with an interest in
                          science and technology. Learning about how electronic
                          devices and instruments are used to diagnose, monitor,
                          and treat medical conditions can be both
                          intellectually stimulating and personally rewarding.
                        </p>
                      </div>
                    </div>
                  </div>
                  <Heading>
                    Who should learn Biomedical Instrumentation ?
                  </Heading>
                  <p>
                    <SubHeading>Students interested in healthcare</SubHeading>{" "}
                    Students interested in pursuing a career in healthcare, such
                    as doctors, nurses, and medical technicians, can benefit
                    from learning biomedical instrumentation. Understanding the
                    principles and applications of biomedical instrumentation
                    can help these professionals better diagnose, monitor, and
                    treat patients.
                    <br />
                    <br />
                    <SubHeading>Engineers and scientists</SubHeading> Biomedical
                    instrumentation is an interdisciplinary field that combines
                    principles from engineering, biology, physics, and computer
                    science. Engineers and scientists with an interest in
                    healthcare technology can benefit from learning biomedical
                    instrumentation to apply their skills and knowledge to this
                    field.
                    <br />
                    <br />
                    <SubHeading>Medical device designers</SubHeading> Biomedical
                    instrumentation is an essential aspect of medical device
                    design. Designers of medical devices, including implantable
                    devices, wearable technology, and diagnostic tools, can
                    benefit from learning biomedical instrumentation to create
                    better products.
                    <br />
                    <br />
                    <SubHeading>Researchers</SubHeading> Biomedical
                    instrumentation is also used in medical research to study
                    the effects of medications and treatments on the body.
                    Researchers can benefit from learning biomedical
                    instrumentation to collect accurate and reliable data for
                    their studies.
                    <br />
                    <br />
                    <SubHeading>
                      Anyone with an interest in science and technology
                    </SubHeading>{" "}
                    Biomedical instrumentation can be a fascinating subject for
                    anyone interested in science and technology. Learning about
                    how electronic devices and instruments are used to diagnose,
                    monitor, and treat medical conditions can be both
                    intellectually stimulating and personally rewarding.
                    <br />
                    <br />
                    In summary, anyone with an interest in healthcare,
                    engineering, science, or technology can benefit from
                    learning biomedical instrumentation. The applications of
                    biomedical instrumentation are vast, and understanding its
                    principles can lead to many career opportunities and
                    personal growth.
                    <br />
                  </p>
                  <Heading>Learning Objectives</Heading>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>1</Numbered>
                        <h5 className="m-2">
                          Understanding the basic principles of biomedical
                          instrumentation: Students should be able to explain
                          the fundamental principles of various biomedical
                          instrumentation systems, such as electrocardiography
                          (ECG), electroencephalography (EEG), and
                          electromyography (EMG).
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>2</Numbered>
                        <h5 className="m-2">
                          Familiarity with the different types of biomedical
                          instrumentation systems: Students should be familiar
                          with the various types of biomedical instrumentation
                          systems used in clinical practice, research, and
                          medical device design. They should understand the
                          advantages and limitations of each type of
                          instrumentation.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>3</Numbered>
                        <h5 className="m-2">
                          Knowledge of signal processing techniques: Students
                          should be able to apply various signal processing
                          techniques to biomedical signals, such as filtering,
                          amplification, and waveform analysis.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>4</Numbered>
                        <h5 className="m-2">
                          Understanding of medical device design: Students
                          should understand the process of medical device
                          design, including the requirements for regulatory
                          approval and the importance of user-centered design.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>5</Numbered>
                        <h5 className="m-2">
                          Ability to interpret and analyze biomedical data:
                          Students should be able to interpret and analyze data
                          obtained from biomedical instrumentation systems,
                          including ECG, EEG, and EMG data.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>6</Numbered>
                        <h5 className="m-2">
                          Awareness of ethical and legal issues: Students should
                          be aware of the ethical and legal issues associated
                          with biomedical instrumentation, such as patient
                          privacy and the responsibility of healthcare
                          professionals to maintain the accuracy and integrity
                          of medical data.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BMIPage

export const pageQuery = graphql`
  query BMIPage {
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/(biomedical_instrumentation)/.*.(mdx)/" }
      }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
            topLevelDir
            subDir
          }
        }
      }
    }
  }
`
